import React from 'react';
import Navigation from '../components/Navigation';
import '../styles/Shows.css';

function Shows() {
  const upcomingShows = [
    {
      date: 'February 7, 2025',
      location: 'Road House, Cave Creek, AZ',
      ticketLink: 'https://hideaway-usa.com/music/',
    },
    {
      date: 'February 28, 2025',
      location: 'xBk, Des Moines, IA',
      ticketLink: 'https://wl.seetickets.us/event/decoy-trio-jordan-beem-loveyoubye/624679?afflky=xBkLive',
    },
    {
      date: 'March 15, 2025',
      location: 'Ostrich Festival, Chandler, AZ',
      ticketLink: 'https://www.tixr.com/groups/ostrichfestival/events/ostrich-festival-weekend-1-125128?_gl=1*1uwwgak*_ga*NTAxMTg0MjQyLjE3NDE1NDcxMTk.*_ga_K4238EN35M*MTc0MTU0NzExOC4xLjEuMTc0MTU0NzM4Ny42MC4wLjA.&sort=RECOMMENDED&COL=7190&A=L',
    },
    {
      date: 'March 16, 2025',
      location: 'Ostrich Festival, Chandler, AZ',
      ticketLink: 'https://www.tixr.com/groups/ostrichfestival/events/ostrich-festival-weekend-1-125128?_gl=1*1uwwgak*_ga*NTAxMTg0MjQyLjE3NDE1NDcxMTk.*_ga_K4238EN35M*MTc0MTU0NzExOC4xLjEuMTc0MTU0NzM4Ny42MC4wLjA.&sort=RECOMMENDED&COL=7191&A=L',
    },
    {
      date: 'April 6, 2025',
      location: 'Wild West Fest, Maricopa, AZ',
      ticketLink: 'https://www.tixr.com/groups/wwmfest/events/wild-west-music-fest-132444?_gl=1*1ov8cub*_ga*MTkxODAxODcwNS4xNzQxNTQ3NTg2*_ga_7976NJP6NS*MTc0MTU0NzU4NS4xLjAuMTc0MTU0NzU4NS42MC4wLjA.&sort=RECOMMENDED&COL=6164&A=L',
    },
    {
      date: 'March 21, 2025',
      location: 'Road House, Cave Creek, AZ',
      ticketLink: 'https://hideaway-usa.com/music/',
    },
    {
      date: 'April 11, 2025',
      location: 'Last Exit Live, Phoenix, AZ',
      ticketLink: 'https://www.eventbrite.com/e/banana-gun-2-night-stand-tickets-1256231836239?aff=oddtdtcreator',
    },
    {
      date: 'May 2, 2025',
      location: 'Pour Decisions, Scottsdale, AZ',
      ticketLink: 'https://www.pourdecisionsscottsdale.com/',
    },
    {
      date: 'May 16, 2025',
      location: 'Road House, Cave Creek, AZ',
      ticketLink: 'https://hideaway-usa.com/music/',
    }
  ];

  const today = new Date();

  const filteredShows = upcomingShows.filter(show => {
    const showDate = new Date(show.date);
    return showDate >= today;
  });

  return (
    <div className="page">
      <Navigation />
      <div className="content">
        <h1>Upcoming Shows</h1>
        <div className="shows-container">
          {filteredShows.length > 0 ? (
            filteredShows.map((show, index) => (
              <div key={index} className="show-card">
                <h2>{show.date}</h2>
                <p>{show.location}</p>
                <a href={show.ticketLink} target="_blank" rel="noopener noreferrer" className="ticket-button">
                  More Info/Tickets
                </a>
              </div>
            ))
          ) : (
            <p>No upcoming shows.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Shows;

import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
import backgroundVideo from '../assets/SUFF_2.mp4';
import 'font-awesome/css/font-awesome.min.css';


function Home() {
  return (
    <div className="homepage">
      {/* Video Background */}
      <video autoPlay loop muted playsInline className="background-video">
      <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content with Overlay */}
      <div className="homepage-content">
        <h1>loveyoubye.</h1>
        <p>we've been down this road before..</p>
        <Link to="/about"><h3>About Us</h3></Link>
        <Link to="/shows"><h3>Upcoming Shows</h3></Link>
        <Link to="/gallery"><h3>Gallery</h3></Link>
        <Link to="/contact"><h3>Contact</h3></Link>

        {/* Social Media Links */}
        <div className="social-links">
          <a href="https://www.instagram.com/loveyoubyeband/" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </div>

        <iframe title="Spotify" className="spotify" src="https://open.spotify.com/embed/artist/4oRB5Ywn8XExK6juJTMZty?utm_source=generator" width="100%" height="352" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      </div>
    </div>
  );
}

export default Home;
